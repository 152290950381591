<template>
  <div class="mt-2 bg-white-900 mb-14">
    <div class="container mx-auto">
      <h1 class="sr-only">Catalog details</h1>
      <base-breadcrumbs
        :links-list="catalogPage.breadcrumbsList"
        class="mb-6"
      />
      <card-catalog-detail
        @export-to-pdf="exportToPdf(catalog.id)"
        class="mb-9"
        :catalog="catalog"
      />
      <actions-bar
        title="Books"
        title-class="text-2xl"
        placeholder="Search by name, author, ISBN"
        sortByType="books"
        :sortByValue="sortBy"
        :searchValue="searchValue"
        :hasSortByRelevance="hasSortByRelevance"
        @handleSortByChange="handleSortByChange"
        @handleSearchValueChange="searchValue = $event"
        @handleSearch="handleSearch"
        @handleSearchClear="handleSearchClear"
      />
      <list-books
        class="mb-13"
        :show-publishers="false"
        :isBooksLoading="isCatalogBooksLoading"
        :books="catalogBooks"
        :booksCount="catalogBooksCount"
        :filterParams="catalogBooksFilterParams"
        :filters="filters"
        @handleFilters="handleFilters"
        @handleFetchMore="handleFetchMore"
      />
    </div>

    <div class="vue-html2pdf">
      <transition name="transition-anim">
        <section class="pdf-preview" v-if="pdfFile">
          <button @click.self="closePdf()">&times;</button>
          <iframe :src="pdfFile" width="100%" height="100%" />
        </section>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ActionsBar from "@/components/base/ActionsBar.vue";
import CardCatalogDetail from "@/components/cards/CardCatalogDetail.vue";
import TabBooks from "@/components/tabs/Books.vue";
import Breadcrumbs from "@/components/base/Breadcrumbs.vue";
import {
  GET_CATALOG,
  FETCH_CATALOG_BOOKS,
  FETCH_MORE_CATALOG_BOOKS,
  FETCH_CATALOG_BOOKS_FILTER_PARAMS,
} from "@/store/actions.type";
import { RELEVANCE_SORT_BY, DEFAULT_BOOKS_SORT_BY } from "@/common/constants";
import { CatalogService } from "@/common/api.service";

export default {
  name: "CatalogPage",
  components: {
    "card-catalog-detail": CardCatalogDetail,
    "actions-bar": ActionsBar,
    "list-books": TabBooks,
    "base-breadcrumbs": Breadcrumbs,
  },
  async mounted() {
    await this.$store.dispatch(GET_CATALOG, this.$route.params.handle);
    this.fetchCatalogBooks();
    if (this.isAuthenticated) {
      this.fetchCatalogBooksFilterParams();
    }
  },
  data() {
    let filters = {
      imprints: [],
      subjects: [],
      publishingDate: [],
      authors: [],
      priceRange: [],
      available: "",
    };

    if (this.$route.query.fi) {
      filters.imprints = JSON.parse(decodeURIComponent(this.$route.query.fi));
    }

    if (this.$route.query.fs) {
      filters.subjects = JSON.parse(decodeURIComponent(this.$route.query.fs));
    }

    if (this.$route.query.fpd) {
      filters.publishingDate = JSON.parse(
        decodeURIComponent(this.$route.query.fpd)
      );
    }

    if (this.$route.query.fa) {
      filters.authors = JSON.parse(decodeURIComponent(this.$route.query.fa));
    }

    if (this.$route.query.fpr) {
      filters.priceRange = JSON.parse(
        decodeURIComponent(this.$route.query.fpr)
      );
    }

    if (this.$route.query.fav) {
      filters.available = JSON.parse(decodeURIComponent(this.$route.query.fav));
    }

    return {
      searchValue: this.$route.query.q ? this.$route.query.q : "",
      sortBy: this.$route.query.s
        ? this.$route.query.s
        : this.$route.query.q
        ? RELEVANCE_SORT_BY
        : DEFAULT_BOOKS_SORT_BY,
      filters,
      page: 1,
      pdfFile: null,
    };
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "catalogPage",
      "catalog",
      "isCatalogBooksLoading",
      "catalogBooks",
      "catalogBooksCount",
      "catalogBooksFilterParams",
    ]),
    hasSortByRelevance() {
      return this.$route.query.q && this.$route.query.q.length > 0;
    },
  },
  methods: {
    getQueryParams() {
      return { q: this.searchValue, s: this.sortBy, f: this.filters };
    },
    updateQueryParams(isUpdateQuery) {
      let query = {};

      const q = isUpdateQuery ? this.searchValue : this.$route.query.q;
      if (q && q.length > 0) {
        query["q"] = q;
      }

      if (this.sortBy.length > 0) {
        query["s"] = this.sortBy;
      }

      if (this.filters.imprints.length > 0) {
        query["fi"] = encodeURIComponent(JSON.stringify(this.filters.imprints));
      }

      if (this.filters.subjects.length > 0) {
        query["fs"] = encodeURIComponent(JSON.stringify(this.filters.subjects));
      }

      if (this.filters.publishingDate.length > 0) {
        query["fpd"] = encodeURIComponent(
          JSON.stringify(this.filters.publishingDate)
        );
      }

      if (this.filters.authors.length > 0) {
        query["fa"] = encodeURIComponent(JSON.stringify(this.filters.authors));
      }

      if (this.filters.priceRange.length > 0) {
        query["fpr"] = encodeURIComponent(
          JSON.stringify(this.filters.priceRange)
        );
      }

      if (this.filters.available.length > 0) {
        query["fav"] = encodeURIComponent(
          JSON.stringify(this.filters.available)
        );
      }

      this.$router.push({
        name: "catalog",
        params: {
          handle: this.catalog.handle,
        },
        query,
      });
    },
    fetchCatalogBooks() {
      this.$store.dispatch(FETCH_CATALOG_BOOKS, {
        slug: this.catalog.id,
        params: this.getQueryParams(),
      });
    },
    fetchCatalogBooksFilterParams() {
      this.$store.dispatch(FETCH_CATALOG_BOOKS_FILTER_PARAMS, {
        slug: this.catalog.id,
        params: {
          q: this.searchValue,
        },
      });
    },
    resetFilters() {
      this.filters = {
        imprints: [],
        subjects: [],
        publishingDate: [],
        authors: [],
        priceRange: [],
        available: "",
      };
    },
    handleSortByChange(event) {
      this.sortBy = event;
      this.updateQueryParams(false);
    },
    handleSearch() {
      if (this.searchValue.length > 0) {
        this.sortBy = RELEVANCE_SORT_BY;
      } else {
        this.sortBy = DEFAULT_BOOKS_SORT_BY;
      }
      this.updateQueryParams(true);
    },
    handleSearchClear() {
      this.searchValue = "";
      this.sortBy = DEFAULT_BOOKS_SORT_BY;
      this.updateQueryParams(true);
    },
    handleFilters({ key, value }) {
      this.filters = {
        ...this.filters,
        [key]: value,
      };
      this.updateQueryParams(false);
    },
    handleFetchMore() {
      this.page++;
      this.$store.dispatch(FETCH_MORE_CATALOG_BOOKS, {
        slug: this.catalog.id,
        params: {
          ...this.getQueryParams(),
          p: this.page,
        },
      });
    },
    async exportToPdf(slug) {
      const { data } = await CatalogService.getPdf(slug);
      const blob = new Blob([data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      this.pdfFile = blobUrl;
    },
    closePdf() {
      URL.revokeObjectURL(this.pdfFile);
      this.pdfFile = null;
    },
  },
};
</script>
