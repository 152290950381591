<template>
  <div class="flex space-x-6">
    <!-- buttons wrapper -->
    <div
      v-if="isAuthenticated"
      class="flex border border-grey-500 rounded max-w-xs w-full p-6"
    >
      <div class="m-auto">
        <base-button
          @click.native="handleClick"
          :big="true"
          :active="catalog.shortlistIds && catalog.shortlistIds.length > 0"
          colorScheme="orange"
          class="mb-3"
        >
          <svg
            v-if="catalog.shortlistIds && catalog.shortlistIds.length > 0"
            class="mr-2"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
          <svg
            v-else
            class="mr-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.5523 9.5043L12 4.3331L9.44772 9.5043L3.74112 10.3336L7.87038 14.3588L6.89574 20.0425L12.0001 17.3589L17.1041 20.0424L16.1292 14.3588L20.2588 10.3336L14.5523 9.5043ZM1.43235 10.1777L6.25868 14.8825L5.1195 21.5257C5.07891 21.7622 5.17615 22.0011 5.37026 22.1422C5.48007 22.222 5.61013 22.2626 5.74082 22.2626C5.84118 22.2626 5.94187 22.2387 6.03407 22.1902L12 19.0536L17.9658 22.1901C18.1782 22.3018 18.4355 22.2832 18.6296 22.1422C18.8237 22.0011 18.921 21.7621 18.8805 21.5256L17.7409 14.8825L22.5678 10.1777C22.7396 10.0103 22.8015 9.75978 22.7273 9.53159C22.6531 9.30336 22.4559 9.13703 22.2185 9.10257L15.5484 8.13329L12.5653 2.08915C12.4591 1.87399 12.24 1.73779 12.0001 1.73779C11.7601 1.73779 11.541 1.87399 11.4348 2.08915L8.45165 8.13329L1.78165 9.10257C1.54426 9.13703 1.34695 9.30336 1.27283 9.53154C1.19865 9.75978 1.26051 10.0103 1.43235 10.1777Z"
              fill="currentColor"
            />
          </svg>

          To Shortlist
        </base-button>
        <base-button
          v-if="user.type === 'admin' || user.type === 'reseller'"
          :big="true"
          @click.native="exportToPdf"
        >
          <svg
            class="mr-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.4288 7V19.7142C19.4288 20.8188 18.5334 21.7142 17.4288 21.7142H7.14307C6.0385 21.7142 5.14307 20.8188 5.14307 19.7142V4.28564C5.14307 3.18107 6.0385 2.28564 7.14307 2.28564H15.0002M19.4288 7L15.0002 2.28564M19.4288 7H16.0002C15.4479 7 15.0002 6.55228 15.0002 6V2.28564M12.2859 8.85707V15.9999M12.2859 15.9999L15.1431 13.4285M12.2859 15.9999L9.14307 13.4285"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Export to PDF
        </base-button>
      </div>
    </div>
    <!-- catalog detail -->
    <div
      class="
        flex
        items-start
        rounded
        bg-grey-400
        px-5
        pt-5
        pb-6
        bg-catalog bg-no-repeat bg-right-bottom
        w-full
      "
      :style="{ 'background-image': `url(${require('@/assets/book-bg.svg')})` }"
    >
      <router-link
        v-if="catalog.handle"
        :to="{ name: 'catalog', params: { handle: catalog.handle } }"
      >
        <img
          :src="catalog.imageUrl"
          :alt="catalog.name"
          class="shadow-md h-auto mr-6"
          width="134"
        />
      </router-link>
      <div class="max-w-xl">
        <router-link
          v-if="catalog.handle"
          :to="{ name: 'catalog', params: { handle: catalog.handle } }"
        >
          <h2
            class="
              text-black-900
              font-medium
              text-2xl
              mb-2
              inline-block
              hover:text-orange-900
            "
          >
            {{ catalog.name }}
          </h2>
        </router-link>
        <router-link
          v-if="catalog.publisherHandle"
          :to="{
            name: 'publisher',
            params: { handle: catalog.publisherHandle },
          }"
        >
          <h3 class="text-grey-700 font-medium text-base mb-3 hover:underline">
            {{ catalog.publisherName }}
          </h3>
        </router-link>
        <p class="text-black-600 text-sm mb-3">
          {{ catalog.description }}
        </p>
        <div class="flex space-x-10">
          <div>
            <h5 class="text-grey-700 text-sm">Added:</h5>
            <p class="text-black-600 text-sm">{{ catalog.createdAt }}</p>
          </div>
          <div>
            <h5 class="text-grey-700 text-sm">Updated:</h5>
            <p class="text-black-600 text-sm">{{ catalog.updatedAt }}</p>
          </div>
        </div>
      </div>
      <router-link
        v-if="catalog.publisherHandle"
        :to="{ name: 'publisher', params: { handle: catalog.publisherHandle } }"
        class="w-18 h-18 flex rounded bg-white-900 ml-auto p-1"
      >
        <img
          :src="catalog.publisherImageUrl"
          :alt="catalog.publisherName"
          class="h-full object-contain m-auto max-w-60"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BaseButton from "@/components/utils/Button.vue";
import { LOAD_SELECTED_SHORTLIST } from "@/store/actions.type";

export default {
  name: "CardCatalogDetail",
  components: {
    "base-button": BaseButton,
  },
  props: {
    catalog: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
  },
  methods: {
    async handleClick() {
      if (this.isAuthenticated) {
        await this.$store.dispatch(LOAD_SELECTED_SHORTLIST, {
          shortlistIds: [...this.catalog.shortlistIds],
          bookId: null,
          catalogId: this.catalog.id,
        });
        this.$modal.show("shortlist-modal", {
          component: "add-shortlist-modal",
        });
      } else {
        // redirect to login page if not yet authenticated
        this.$router.push({
          name: "login",
          query: { redirect: this.$route.path },
        });
      }
    },
    exportToPdf() {
      this.$emit("export-to-pdf");
    },
  },
};
</script>
